<template>
  <div>
    <Navbar :isShare="isShare" :isShowSearchInput="isShowSearchInput">
      <SearchInput
        :searchValue="searchName"
        @search="search"
        @changeSearch="changeSearch"
        slot="searchInput"
      />
    </Navbar>
    <v-main>
      <v-container>
        <div class="d-flex justify-space-between">
          <span
            class="text-title"
            v-html="
              $i18n.locale === 'th_TH'
                ? $t(`search.historySearch`)
                : $t(`search.historySearch`)
            "
          >
          </span>
          <v-btn icon fab small @click="deleteSearch">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>

        <v-row v-if="SearchHistory.length > 0">
          <v-col
            cols="4"
            lg="4"
            v-for="(item, index) in SearchHistory.slice(0, 9)"
            :key="index"
          >
            <v-card
              class="rounded-card pa-2 text-center"
              elevation="0"
              @click="toSearch(item)"
            >
              <div class="text-subtitle" v-html="item"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import SearchInput from '@/components/Main/Search/Search'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Search',
  components: {
    SearchInput,
  },
  data() {
    return {
      isShare: true,
      searchName: '',
      isShowSearchInput: true,
    }
  },
  computed: {
    ...mapGetters({ SearchHistory: 'SearchHistoryStore/SearchHistory' }),
  },
  methods: {
    ...mapMutations({
      delSearchHistory: 'SearchHistoryStore/delSearchHistory',
    }),
    search() {
      setTimeout(() => {
        this.toSearch(this.searchName.replace(/(^\s*)|(\s*$)/g, ''))
      }, 200)
    },
    toSearch(text) {
      this.$router.push({
        name: 'SearchList',
        params: { searchName: text },
      })
    },
    deleteSearch() {
      this.delSearchHistory()
    },
    async changeSearch(val) {
      this.searchName = val
      this.getCompleteData()
    },
    async getCompleteData() {
      if (this.searchName.length > 2) {
        console.log(this.searchName.length)
      }
    },
  },
}
</script>

<style scoped>
.text-title {
  color: #707070;
}

.rounded-card {
  border-radius: 30px;
}

.text-subtitle {
  font-size: 13px;
}
</style>
